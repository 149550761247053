import { Injectable } from '@angular/core';
import { BaseService } from '../../api/base-service';
import {HttpClient, HttpResponse} from '@angular/common/http';
import { ApiConfiguration } from '../../api/api-configuration';
import * as utils from './availability.utils';
import * as _ from 'lodash';
import * as moment from 'moment';
import 'moment-duration-format';
import {EMPTY, Observable, of, throwError} from 'rxjs';
import { Params } from '@angular/router';
import {
	Availability,
	AvailabilityAPIReturn,
	AvailabilityDetails, AvailabilityObject,
	AvailabilityRow,
	DivisionFilterOption,
} from './availability.types';
import { environment } from '../../../environments/environment';
import {LocalStorageService, SELECTED_ENTITY} from '../../local-storage.service';
import {catchError, filter, map} from 'rxjs/operators';

const ROWS_TO_DISPLAY = 5;

@Injectable({
	providedIn: 'root',
})
export class AvailabilityService extends BaseService {

	constructor(config: ApiConfiguration, http: HttpClient, private localStorageService: LocalStorageService) {
		super(config, http);
	}

	static makeTableRows(availabilities: Availability[] | { [index: string]: any }): AvailabilityRow[] {
		return _.map(AvailabilityService.sortAvailabilities(availabilities), AvailabilityService.convertDriverTimes);
	}

	/**
	 * loop through drivers data to get all Divisions
	 * available to filter by
	 * @returns {undefined}
	 */
	static makeDivisionFilterOptions(data: any): any[] {
		//todo only used for dispatchers? or is useless with api call replacing
		const divisions = _.uniq(_.map(_.filter(data, 'driver_id'), 'driver_location'));
		return _.concat([{ name: 'All Divisions', value: '' }], _.map(divisions, (division) => ({ name: division, value: division })));
	}

	static makeAvailabilityByDivision(data: Availability[]): { [divisionName: string]: AvailabilityRow } {
		const result = {};
		_.map(data, (avail) => {
			const divisionName = avail.driver_location;
			if (!result[divisionName]) {
				result[divisionName] = {
					avail_on: 0,
					duty_period: 0,
					divisionName,
					avail_d: 0,
					lowestAvailability: 0,
				};
			}
			result[divisionName].duty_period += avail.duty_period;
			result[divisionName].avail_on += avail.avail_on;
			result[divisionName].avail_d += avail.avail_d;
			result[divisionName].lowestAvailability = Math.min(avail.avail_on, avail.avail_d, avail.duty_period);
		});
		return result;
	}

	/**
	 * Orders an array of objects by the duty period, on hours, and driver hours in descending order
	 * @returns {Array}
	 */
	static sortAvailabilities(availabilities: Availability[] | { [index: string]: Availability }): any[] {
		return _.orderBy(availabilities, ['lowestAvailability'], ['desc']) as any[];
	}

	static convertDriverTimes(avail: Availability): AvailabilityRow {
		return {
			...avail,
			lowestAvailability: avail.lowestAvailability,
			avail_d: AvailabilityService.secondsToHoursMinutes(avail.avail_d),
			avail_on: AvailabilityService.secondsToHoursMinutes(avail.avail_on),
			duty_period: AvailabilityService.secondsToHoursMinutes(avail.duty_period),
		};
	}

	/**
	 * returns the number of hours and minutes in h:mm format
	 * @param {number} seconds
	 * @return {string} hours and minutes in h:mm format
	 */
	static secondsToHoursMinutes(seconds) {
		return moment.duration(seconds, 'seconds').format('h:mm');
	}

	public renderDataModel(availabilityObject: AvailabilityObject, queryParams: Params): AvailabilityDetails {

		const driverAvailability = availabilityObject.availability;
		_.forEach(driverAvailability, (avail) => {
			avail['lowestAvailability'] = Math.min(avail.avail_on, avail.avail_d, avail.duty_period);
		});

		const availabilityGroupedByDivision = _.mapValues(_.groupBy(driverAvailability, 'driver_location'),
			(value) => [{
				headers: utils.BY_DRIVER_TABLE_HEADERS,
				rows: AvailabilityService.makeTableRows(value),
				displayedColumns: utils.BY_DRIVER_TABLE_DISPLAYED_COLUMNS,
			}]);

		const divisionFilterOptions = AvailabilityService.makeDivisionFilterOptions(driverAvailability);
		const tableData = queryParams.division
			? availabilityGroupedByDivision[queryParams.division]
			: [{
				headers: utils.DIVISION_TABLE_HEADERS,
				rows: AvailabilityService.makeTableRows(AvailabilityService.makeAvailabilityByDivision(driverAvailability)),
				displayedColumns: utils.DISPLAYED_COLUMNS,
			}];

		return {
			...availabilityObject,
			isLoading: false,
			isLoadSuccess: true,
			isError: false,
			availabilityGroupedByDivision,
			divisionFilterOptions: _.sortBy(divisionFilterOptions, 'name'),
			tableData,
			offset: availabilityObject.offset + availabilityObject.limit,
			total_number_of_drivers: availabilityObject.total_number_of_drivers,
		};
	}

	public getReportDetailsData(queryParams: Params): Observable<any> {
		const params = _.clone(queryParams);
		params.account_code = this.localStorageService.get('activeAccount');
		params.limit = queryParams.limit || 500;
		params.offset = queryParams.offset || 0;
		params.day = moment().format("YYYY-MM-DD");
		let idString;
		if (this.localStorageService.getDispatcherId()){
			idString =  this.localStorageService.getDispatcherId();
			params.dispatcher = Number(idString.substring(idString.indexOf(':')+1));
		} else {
			idString =  this.localStorageService.getLocationId();
			params.location = Number(idString.substring(idString.indexOf(':')+1));
		}
		return this.http.get(`${environment.urls.hosBackendApi}/api/v1/availability`, { params , observe: 'response' })
	}

	public getAvailabilityv2(queryParams: any): Observable<any> {
		const params = _.clone(queryParams);

		const selectedEntity = this.localStorageService.get(SELECTED_ENTITY);
		// TODO: remove this check when location toggle is removed
		if(!selectedEntity || !selectedEntity.value || !selectedEntity.value.split(':').length || selectedEntity.entityType === 'dispatcherId') {
			return throwError({message:"Location Id not found",
				details: 'Please select location from above.', statusText: 400});
		}
		params.account_code = this.localStorageService.get('activeAccount');
		params.limit = queryParams.limit || 50;
		return this.http.get(`${environment.urls.hosBackendApi}/api/v2/availability`, { params , observe: 'response' }).pipe(
			catchError((err) => {
				return EMPTY;
			}),
			filter(r => !!r),
			map((data: any) => {
				let after = undefined;
				if (data.body && data.body.length == queryParams.limit) {
					after = data.body[queryParams.limit - 1].driver_id;
				}
				return {
					data: data.body,
					after
				};
			})
		)
	}

	// public getDivisionAvailabilityByDispatcher(dispatcher_id): Observable<any> {
	// 	let params = {
	// 		account_code: this.localStorageService.get('activeAccount'),
	// 		dispatcher_id
	// 	};
	// 	// TODO: remove this check when location/dispatcher toggle is removed
	// 	if (!params.dispatcher_id) {
	// 		return throwError({message:"Dispatcher Id or Location Id not found",
	// 			name: 'Please select either from above.', statusText: 400});
	// 	}
	// 	return this.http.get(`${environment.urls.hosBackendApi}/api/v2/availability/by_location`, { params , observe: 'body' })
	// }

	public getCSVExport(qParams: {dispatcher_id?: number; location_id?: number; units?: string}): Observable<any> {
		const selectedEntity = this.localStorageService.get(SELECTED_ENTITY);
		// TODO: remove this check when location toggle is removed
		if(!selectedEntity || !selectedEntity.value || !selectedEntity.value.split(':').length || selectedEntity.entityType === 'dispatcherId') {
			return throwError({msg:"Location Id not found",
				details: 'Please select location from above.', status: 400});
		}
		let params = {
			...qParams,
			account_code: this.localStorageService.get('activeAccount'),
		};
		return this.http.get(`${environment.urls.hosBackendApi}/api/v2/availability/csv`, { params , observe: 'body', responseType: 'text' })
			.pipe(map((res => res)), catchError(error => {
				if (error && error.status) {
					console.error(
						`Backend returned code ${error.status}, body was: ${error.error || error?.message}`);
					return throwError({ msg: error?.error?.message || error?.message, status: error?.status});
				}
				console.error('An error occurred:', error.error);
				// Return an observable with a user-facing error message.
				return throwError({msg: 'An error has taken place while exporting csv', details: 'Please try again later', status: error?.status});
			}));
	}
}
